<template>
  <!-- <keep-alive>
    <router-view />
  </keep-alive> -->

  <div>
      <router-view v-slot="{ Component }">
            <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
            <keep-alive>
              <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
            </keep-alive>
      </router-view>

      <!-- <van-tabbar v-model="active" route active-color="#ee0a24" v-if="state.isShowTab">
          <van-tabbar-item name="homeIn" icon="wap-home-o" to="/homeIn">首页</van-tabbar-item>
          <van-tabbar-item name="userIn" icon="manager-o" to="/userIn">个人中心</van-tabbar-item>
      </van-tabbar> -->
  </div>
  

</template>

<script>

import { ref, watch, reactive } from "vue";
import { useRoute } from "vue-router";

export default {
  name: 'App',
  // data: function() {
  //   return {
  //     active:"/userIn",
  //     isShowTab: true
  //   };
  // },
  setup() {
    const active = ref(0);
    const route = useRoute();
 
    const state = reactive({
      isShowTab: true, //默认展示底部的tab
    });
 
    // 监听路由中的信息是否为tab页，如果为tab页，展示，否则，不展示底部tab
    watch(
      () => route.meta,
      (val) => {
        state.isShowTab = val.isTab;
      }
    );
    return { active, state };
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  background: #f5f5f5;
  min-height: 100vh;
}
</style>
