import wx from 'jweixin-module';
import { userLogin } from "@/api/user";
import { setToken, getToken, setkeyCode, setaccord } from '@/utils/cookie'

const isWechat = () => {
    return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
};

export function CodeBtn(){
    if (isWechat()) {
      let appid = "wx5feb63b1d8fb4739";
      let code = getUrlParam("code"); //是否存在code
      let codeadd = getToken();
      let local = window.location.href;
      if(codeadd == null || codeadd === ""){
        if (code == null || code === "") {
          window.location.href =
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;

        }else{
          initWechatSDK(code);
        }
      }
      
    }
}
function getUrlParam(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
}

// 初始化微信SDK的函数
function initWechatSDK(codekey) {
  // 使用你的后端接口获取微信签名等配置信息
//   return new Promise((resolve, reject) => {
    // 假设后端提供了一个获取微信配置信息的接口 /wechat/getConfig
    userLogin({ code: codekey})
    .then(response => {
        console.log(response);
        const data = response.data;
        setToken(data.access_token);
        setkeyCode(data.refresh_token);
        setaccord(data.token_type);

        let calbUrl = new URL(window.location.href);
        window.location.href = calbUrl.origin + '/' + calbUrl.hash;
    //     const config = response.data;
    //     // 配置微信JS-SDK
    //     wx.config({
    //       debug: false, // 是否开启调试模式
    //       appId: config.appId, // 必填，公众号的唯一标识
    //       timestamp: config.timestamp, // 必填，生成签名的时间戳
    //       nonceStr: config.nonceStr, // 必填，生成签名的随机串
    //       signature: config.signature, // 必填，签名
    //       jsApiList: ['chooseImage', 'previewImage', 'uploadImage', 'downloadImage'] // 需要使用的JS接口列表
    //     });

    //     // 微信JS-SDK配置成功后调用resolve函数
    //     wx.ready(() => {
    //       console.log('微信JS-SDK配置成功');
    //     //   resolve();
    //     });

    //     // 微信JS-SDK配置失败时调用reject函数
    //     wx.error(error => {
    //       console.error('微信JS-SDK配置失败', error);
    //     //   reject(error);
    //     });
    //   })
    //   .catch(error => {
    //     console.error('获取微信配置信息失败', error);
    //     // reject(error);
    //   });
  });
}

// 导出 wx 对象，以便在任何组件中使用
export default wx;