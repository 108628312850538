import request from './request'

/**
  * @description 验证码
  */
export function loginSendSms(data) {
    return request.post("sendSms", data);
}

/*
 * 登录
 * 
*/
export function userLogin(data){
    return request.post('/api/login', data);
}
/*
 * 刷新token
 * 
*/
export function refreshToken(data){
    return request.post('/api/refresh_token', data);
}
/*
 * 用户信息
 * 
*/
export function UserInfo(data){
    return request.post('/api/userInfo', data);
}
/*
 * 上传图片
 * 
*/
export function callbackImg(data){
    return request.post('/oss/callback', data);
}
/*
 * 上传图片签名
 * 
*/
export function uploadGetSignatureApi(data){
    return request.post('/api/uploadGetSignature', data);
}
/*
 * 身份配置
 * 
*/
export function getIdentityApi(data){
    return request.post('/api/getIdentity', data);
}
/*
 * 辅助数据
 * 
*/
export function getAncillaryDataApi(data){
    return request.post('/api/getAncillaryData', data);
}
/*
 * 实名认证
 * 
*/
export function certificationApi(data){
    return request.post('/api/certification', data);
}
/*
 * 实名认证信息
 * 
*/
export function getCertificationApi(data){
    return request.post('/api/getCertification', data);
}
/*
 * 预约上牌
 * 
*/
export function makeApi(data){
    return request.post('/api/make', data);
}
/*
 * 预约列表
 * 
*/
export function getMakeListApi(data){
    return request.post('/api/getMakeList', data);
}
/*
 * 预约详情
 * 
*/
export function getMakeDetailsApi(data){
    return request.post('/api/getMakeDetails', data);
}
/*
 * 用户违规列表
 * 
*/
export function userViolationApi(data){
    return request.post('/api/userViolation', data);
}
/*
 * 违规列表
 * 
*/
export function getViolationListApi(data){
    return request.post('/api/getViolationList', data);
}
/*
 * 车牌信息
 * 
*/
export function getPlateDetailsApi(data){
    return request.post('/api/getPlateDetails', data);
}
/*
 * 违规上报
 * 
*/
export function plateViolationApi(data){
    return request.post('/api/plateViolation', data);
}
/*
 * 首页轮播
 * 
*/
export function homeBanner(data){
    return request.post('banner', data, { noAuth : true });
}

/*
 * 推广下级
 * 
*/
export function getPeopleApi(data){
    return request.post('getPeople', data);
}
/*
 * 推广二维码
 * 
*/
export function getQrCodeApi(data){
    return request.post('getQrCode', data);
}

/*
 * 用户信息
 * 
*/
export function getUserInfo(data){
    return request.post('userInfo', data);
}
/*
 * 积分列表
 * 
*/
export function userBillApi(data){
    return request.post('userBill', data);
}
/*
 * 任务列表
 * 
*/
export function userTaskListApi(data){
    return request.post('userTaskList', data);
}
/*
 * 赠送积分验证码
 * 
*/
export function giveSendSmsApi(data){
    return request.post('giveSendSms', data);
}
/*
* 赠送积分
* 
*/
export function giveScoreApi(data){
    return request.post('giveScore', data);
}
/*
 * 积分支付
 * 
*/
export function scoreQrCodeApi(data){
    return request.post('scoreQrCode', data);
}
/*
 * 申请推广员
 * 
*/
export function applyPromoterApi(data){
    return request.post('applyPromoter', data, { noAuth : true });
}
/*
 * 下载app
 * 
*/
export function appVersionApi(data){
    return request.post('appVersion', data);
}
/*
 * 用户资产
 * 
*/
export function getUserPropertyApi(data){
    return request.post('getUserProperty', data);
}