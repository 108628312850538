import Cookies from 'js-cookie'

const TokenKey = 'H-Token'
const TokenKeyCode = 'H-Code'
const TokenAccord = 'H-Accord'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getkeyCode() {
  return Cookies.get(TokenKeyCode)
}

export function setkeyCode(code) {
  return Cookies.set(TokenKeyCode, code)
}

export function removekeyCode() {
  return Cookies.remove(TokenKeyCode)
}

export function getaccord() {
    return Cookies.get(TokenAccord)
}
  
export function setaccord(code) {
    return Cookies.set(TokenAccord, code)
}

export function removeaccord() {
    return Cookies.remove(TokenAccord)
}
