import { createRouter, createWebHashHistory } from 'vue-router'
// import UserIn from '../views/Home.vue'

const routes = [
//   {
//     path: '/',
//     name: 'UserIn',
//     component: UserIn
//   },
  {
    path: '/',
    redirect: `/homeIn`,
    children: [
      {
        path: `/homeIn`,
        component: () => import('@/views/HomeIn'),
        name: 'HomeIn',
        meta: {
          title: '首页',
          noCache: true,
          keepAlive: true,
          isTab: true
        },
      },
      // {
      //   path: `/userIn`,
      //   component: () => import('@/views/Home'),
      //   name: 'UserIn',
      //   meta: {
      //     title: '首页',
      //     noCache: true,
      //     keepAlive: true,
      //     isTab: true
      //   },
      // },
      {
        path: `/userIn`,
        component: () => import('@/views/UserIn'),
        name: 'UserIn',
        meta: {
          title: '我的',
          noCache: true,
          keepAlive: true,
          isTab: true
        },
      }
    ]
  },
  {
    path: `/homeIn/appointment`,
    name: 'HomeInAppointment',
    component: () => import('@/views/userFeature/Appointment')
  },
  {
    path: `/homeIn/appointmentList`,
    name: 'HomeInAppointmentList',
    component: () => import('@/views/userFeature/AppointmentList')
  },
  {
    path: `/homeIn/appointmentDetail/:id`,
    name: 'HomeInAppointmentDetail',
    component: () => import('@/views/userFeature/AppointmentDetail')
  },
  {
    path: `/homeIn/userdisobeyList`,
    name: 'HomeInUserdisobeyList',
    component: () => import('@/views/userFeature/UserdisobeyList')
  },
  {
    path: `/homeIn/disobeyList`,
    name: 'HomeInDisobeyList',
    component: () => import('@/views/userFeature/DisobeyList')
  },
  {
    path: `/homeIn/qrcode`,
    name: 'HomeInQrcode',
    component: () => import('@/views/userFeature/Qrcode')
  },
  {
    path: `/supervise`,
    name: 'HomeInSupervise',
    component: () => import('@/views/userFeature/Supervise')
  },
  {
    path: `/homeIn/realname`,
    name: 'HomeInRealname',
    component: () => import('@/views/userFeature/Aealname')
  },
  {
    path: `/userIn/integral`,
    name: 'UserIntegral',
    component: () => import('@/views/userDatabase/Integral')
  },
  {
    path: `/userIn/presentexp`,
    name: 'UserPresentexp',
    component: () => import('@/views/userDatabase/Presentexp')
  },
  {
    path: `/userIn/inpayment`,
    name: 'UserInpayment',
    component: () => import('@/views/userDatabase/Inpayment')
  },
  {
    path: `/userIn/promoter`,
    name: 'UserPromoter',
    component: () => import('@/views/userDatabase/Promoter')
  },
  {
    path: `/userIn/usertask`,
    name: 'InuserTask',
    component: () => import('@/views/userDatabase/Usertask')
  },
  {
    path: `/userIn/promocode`,
    name: 'UserPromocode',
    component: () => import('@/views/userDatabase/Promocode')
  },
  {
    path: `/userIn/comedown`,
    name: 'ComeDown',
    component: () => import('@/views/userDatabase/Comedown')
  },
  {
    path: `/userIn/userasset`,
    name: 'UserAsset',
    component: () => import('@/views/userDatabase/Userasset')
  },
  {
    path: `/userorder/orderlist`,
    name: 'OrderList',
    component: () => import('@/views/userOrder/Orderlist')
  },
  {
    path: `/login`,
    name: 'UsersLogin',
    component: () => import('@/views/Login')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export function resetRouter() {
    const newRouter = router
    router.matcher = newRouter.matcher // reset router
}

export default router
