import axios from 'axios'
// import { setToken } from '@/utils/cookie'
import { getToken, getkeyCode, getaccord, setToken } from '@/utils/cookie'
// import router, { resetRouter } from '@/router'

const instance = axios.create({
  // baseURL: 'http://192.168.31.144:8112' + '/api' ,
  baseURL: 'https://test.api.license.ssslide.com',
  // baseURL: 'https://test.api.license.ssslide.com' + '/api' ,
//   baseURL: 'https://api.daguojuxiang.com' + '/api' ,
  timeout: 50000
})
const defaultOpt = { login: true }


// 存储由于 token 过期导致 失败的请求
let expiredRequestArr = [];

/**
 * 存储当前因为 token 失效导致发送失败的请求
 */
const saveErrorRequest = (expiredRequest) => {
  expiredRequestArr.push(expiredRequest);
}

/**
 * 执行当前存储的由于过期导致失败的请求
 */
const againRequest = () => {
  expiredRequestArr.forEach(resconfig => {
    // request();
    request.post(resconfig.config.url, resconfig.config.data);
  })
  clearExpiredRequest();
}

/**
 * 清空当前存储的过期请求
 */
export const clearExpiredRequest = () => {
  expiredRequestArr = [];
}


function baseRequest(options) {
  // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aGluay50aW55d2FuLmNuIiwiYXVkIjoidGhpbmsudGlueXdhbi5jbiIsImlhdCI6MTcxNDA5ODA0MSwibmJmIjoxNzE0MDk4MTAxLCJleHAiOjE3MTQxODQ0NDEsImV4dGVuZCI6eyJpZCI6MywiY2xpZW50IjoiSDUifX0.gJ_-GImSYzxXche5ZcWEGAW0iLgR01Aig0bqkCFt1kk";
  // const code = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aGluay50aW55d2FuLmNuIiwiYXVkIjoidGhpbmsudGlueXdhbi5jbiIsImlhdCI6MTcxNDA5ODA0MSwibmJmIjoxNzE0MDk4MTAxLCJleHAiOjE3MTQ3MDI4NDEsImV4dGVuZCI6eyJpZCI6MywiY2xpZW50IjoiSDUifX0.PSrwxEI9wtnXyhTNMwnTuyzpLX1AsQID367Mpk2MltM";
  // const accord = "Bearer";
  const token = getToken();
  const code = getkeyCode();
  const accord = getaccord();
  const headers = options.headers || {}
  if (token) {
    if(options.url == "/api/refresh_token"){
      headers['Authorization'] = accord + ' ' + code
      options.headers = headers
    }else{
      headers['Authorization'] = accord + ' ' + token
      options.headers = headers
    }
  } else {
    options.headers = headers
  }
  return instance(options).then(res => {
    const data = res.data || {}
    // if (res.status !== 200) {
    //   console.log('请求失败', res);
    //   return Promise.reject({ message: '请求失败', res, data })
    // }

    if (data.code === 200) {
      if(options.url == "/api/refresh_token"){
        setToken(data.access_token);
            /**
         * 当获取了最新的 refreshToken, accessToken 后
         * 重新发起之前失败的请求
         */
        againRequest();
      }else{
        return Promise.resolve(data, res)
      }
    } else

    if (data.code === 1002 || data.code === 1003) {
      return request.post('/api/refresh_token', data);
      // removeToken()
      // removekeyCode()
      // removeaccord()
      // resetRouter()
      
      // router.push("/login");

      // return Promise.reject({ message: data.message, res, data })
    } else {
      return Promise.reject({ message: data.message, res, data })
    }
  }).catch(error => {
      if(error.response.data.code === 1002 || error.response.data.code === 1003){
        saveErrorRequest(error);
        return request.post('/api/refresh_token');
      }
      
  });
}
/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ['post', 'put', 'patch', 'delete'].reduce((request, method) => {
  /**
   *
   * @param url string 接口地址
   * @param data object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, data = {}, options = {}) => {
    return baseRequest(
      Object.assign({ url, data, method }, defaultOpt, options)
    )
  }
  return request
}, {});

['get', 'head'].forEach(method => {
  /**
   *
   * @param url string 接口地址
   * @param params object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, params = {}, options = {}) => {
    return baseRequest(
      Object.assign({ url, params, method }, defaultOpt, options)
    )
  }
})

export default request
