import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import { CodeBtn } from './utils/wechat';
CodeBtn();

import vant from 'vant';
import 'vant/lib/index.css'

createApp(App).use(router).use(vant).mount('#app')
